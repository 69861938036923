
<template>
  <TopNavBar/>
  <div class="container">
    <router-view v-slot="{ Component, route }">
      <transition name="fadee">
        <component :is="Component" :key="route.path"/>
      </transition>
    </router-view>
    <div v-if="$isDev" style="font-size:12px">{{ debugToken }}</div>
  </div>
</template>

<script>
/* eslint-disable */ 

import TopNavBar from './components/TopNavBar.vue'
import NewPropertyPage from './components/NewPropertyPage.vue'

export default {
  name: 'App',
  components: {
    TopNavBar,
    NewPropertyPage
  },
  data() {
    return {
      route: this.$route.path,
      debugToken: localStorage.getItem('token')
    }
  },
  methods: {
  }
}
</script>

<style>
.fade-leave { opacity: 1; }
.fade-leave-active { transition: opacity 0.2s }
.fade-leave-to { opacity: 0; }
.fade-enter { opacity: 0; }
.fade-enter-active  { transition: opacity 0.2s }
.fade-enter-to { opacity: 1; }
</style>
