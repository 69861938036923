<template>
    <!-- Login page -->
    <section class="py-md-5 mt-2">
        <div class="mt-5">
            <div class="row">
            <div class="col-md-6 text-center"><img class="img-fluid w-100" src="assets/img/illustrations/login.svg" /></div>
                <div class="col-md-5 col-xl-4 text-center text-md-start">
                    <h2 class="display-6 fw-bold mb-5"><span class="underline pb-1"><strong>Login</strong><br /></span></h2>
                    <div class="alert alert-danger" role="alert" v-if="errorMessage">
                        {{ errorMessage }}
                    </div>
                    <form>
                        <div class="mb-3"><input class="shadow form-control" v-model="email" id="email" type="text" name="email" placeholder="Email" /></div>
                        <div class="mb-3"><input class="shadow form-control" v-model="password" id="password" type="password" name="password" placeholder="Password" /></div>
                        <div class="mb-5"><a class="btn btn-primary shadow" @click="login">Log in</a></div>
                        <p class="text-muted"><a href="forgotten-password.html">Forgot your password?</a></p>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
/* eslint-disable */ 

import LoadingSpinner from './LoadingSpinner.vue';

function login()
{
    this.$auth.login(this.email, this.password)
        .then(response => {
            this.errorMessage = '';

            this.$router.push({ name: 'app' });
        })
        .catch(error => {
            this.errorMessage = error.message;
        });
}

export default {
    name: 'LoginPage',
    components: {
        LoadingSpinner,
    },
    data() {
        return {
            email: '',
            password: '',
            errorMessage: '',
        };
    },
    methods: {
        login
    },
    mounted() {
        // check if user is already logged in
        var token = localStorage.getItem('token');
        if (token) {
            this.$router.push({ name: 'app' });
        }
    },
}



</script>

<style scoped>
</style>
