<template>
    <section class="py-5 mt-2">
        <div class="mt-5">
            <Transition>
                <PropertyOptions v-if="pageIndex == 0" />
            </Transition>
            <div v-if="pageIndex == 0" style="text-align: right;">
                <button class="btn btn-primary" @click="start_generation">Next</button>
            </div>

            <Transition>
                <LoadingSpinner v-if="pageIndex == 1" />
            </Transition>

            <Transition>
                <div v-if="pageIndex == 2">
                    <PropertyGeneration/>
                    <button class="btn btn-primary" @click="set_page(0)">Regenerate</button>
                </div>
            </Transition>

            <Transition>
                <div v-if="pageIndex == 3">
                    <h2>Ooops</h2>
                    <p>Something went wrong. Please try again.</p>
                    <hr/>
                    <button class="btn btn-primary" @click="start_generation">Try Again</button>
                </div>
            </Transition>

            <button v-if="$isDev" class="btn btn-primary" @click="goToLogin()">Login Page (Dev)</button>
            <button v-if="$isDev" class="btn btn-primary" @click="test_prefill()">Prefill (Dev)</button>
        </div>
        <!-- <div>
            {{ property }}
        </div> -->
    </section>
</template>

<script>


import PropertyGeneration from './NewProperty/PropertyGeneration.vue';
import PropertyOptions from './NewProperty/PropertyOptions.vue';
import LoadingSpinner from './LoadingSpinner.vue';
import { usePropertyStore } from '@/stores/property';
import { storeToRefs } from 'pinia';

function start_generation() {
    this.set_page(1);

    var prop = this.property;
    prop.writeup = undefined;

    var requestBody = {
        "meta": this.property
    };

    this.$api.post('property/generate', requestBody)
        .then(response => {
            console.log(response);
            this.property.writeup = response.data.result.text;
            this.set_page(2);
        })
        .catch(error => {
            console.log(error);
            this.set_page(3);
        });

    setTimeout(() => {
        
    }, 3000);
}

function set_page(index)
{
    this.pageIndex = -1;
    // wait for the transition to finish
    setTimeout(() => {
        this.pageIndex = index;
    }, 450);
}

export default {
    setup() {
        const propertyStore = usePropertyStore();
        // create reactive refs to property object
        const { property } = storeToRefs(propertyStore);

        return {
            property
        }
    },
    name: 'NewPropertyPage',
    components: {
    PropertyOptions,
    PropertyGeneration,
    LoadingSpinner,
    },
    data() {
        return {
            pageIndex: 0,
        }
    },
    methods: {
        set_page: set_page,
        start_generation: start_generation,
        goToLogin()
        {
            this.$router.push('/login');
        },
        test_prefill()
        {
            this.property.address = "123 Sena Street, Melbourne, VIC";
            this.property.bedrooms = 3;
            this.property.bathrooms = 2;
            this.property.cars = 1;
            this.property.listingType = 1;
            this.property.land_area = 500;
            this.property.price = 500000;
        }
    },
    mounted() {
        if (this.$auth.is_authenticated() == false)
            this.$auth.go_to_login();
    }
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
