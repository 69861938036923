<template>
    <div>
        <section>
            <h3 class="fw-semibold">{{ property.address }}</h3>
        </section>
        <hr>
        <section>
            <form>
                <!-- <textarea v-model="property.writeup" class="form-control">
            </textarea> -->

                <!-- Insert vue html here -->
                <div v-html="property.writeup">
                </div>
            </form>
        </section>
    </div>
</template>

<script>
//import FeatureList from './FeatureList.vue'
//import PropertyOptions from './PropertyOptions.vue';
import featureDef from './featureDb.json'
import { usePropertyStore } from '@/stores/property';
import { storeToRefs } from 'pinia';

function update_features(updatedFeature) {
    // get calling component
    console.log(updatedFeature);
}

export default {
    setup() {
        const propertyStore = usePropertyStore();
        // create reactive refs to property object
        const { property } = storeToRefs(propertyStore);
        return {
            property
        }
    },
    name: 'PropertyGeneration',
    props: {
    },
    components: {
        //FeatureList,
        //PropertyOptions
    },
    data() {
        return {
            featureDef: featureDef
        }
    },
    methods: {
        update_features: update_features
    }
}
</script>