<template>
    <nav class="navbar navbar-light navbar-expand-md fixed-top navbar-shrink py-3" id="mainNav">
        <div class="container">
            <a class="navbar-brand d-flex align-items-center" href="/">
            <span class="blurble-logo"><i class="fa-solid fa-house"></i></span>
            <span class="logo-text">Blurble</span>
        </a>
        <button data-bs-toggle="collapse" class="navbar-toggler" data-bs-target="#navcol-1"><span class="visually-hidden">Toggle navigation</span><span class="navbar-toggler-icon"></span></button>
            <div class="collapse navbar-collapse" id="navcol-1">
                <ul class="navbar-nav mx-auto">
                    <li class="nav-item"><router-link class="nav-link" to="/">Home</router-link></li>
                    <li v-if="loggedIn" class="nav-item"><router-link class="nav-link" to="/app">Property Write up</router-link></li>
                    <!-- <li class="nav-item"><a class="nav-link" href="features.html">Features</a></li>
                    <li class="nav-item"><a class="nav-link" href="integrations.html">Integrations</a></li>
                    <li class="nav-item"><a class="nav-link" href="pricing.html">Pricing</a></li>
                    <li class="nav-item"><a class="nav-link" href="contacts.html">Contacts</a></li> -->
                </ul>

                <div v-if="loggedIn" class="user-info user-select-none" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    <span class="mx-2 fw-bold">{{this.$auth.user.settings.profile.first_name}}</span>
                    <img :src="this.$auth.user.settings.profile.dp" class="border rounded-circle" width="42" height="42" />
                    <div class="dropdown">
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><a class="dropdown-item" href="#" @click="this.$router.push({ name: 'profile' })">Profile</a></li>
                            <li><a class="dropdown-item" href="#" @click="this.$auth.logout()">Log out</a></li>
                        </ul>
                    </div>
                </div>
                <!-- <a v-if="loggedIn"  class="btn btn-primary" role="button" @click="this.$auth.logout()">Log out</a> -->
                <router-link v-if="!loggedIn" class="btn btn-primary" to="/login">Sign in</router-link>
            </div>
        </div>
    </nav>
</template>

<script>

export default {
    name: 'TopNavBar',
    data() {
        return {
            loggedIn: this.$auth.is_authenticated()
        }
    },
    watch: {
        $route() {
            this.loggedIn = this.$auth.is_authenticated();
        }
    },
    mounted() {
        var mainNav = document.querySelector('#mainNav');
        if (mainNav) {
        
          // Collapse Navbar
          var collapseNavbar = function() {

            var scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;

            if (scrollTop > 10) {
              mainNav.classList.add("navbar-shrink");
            } else {
              mainNav.classList.remove("navbar-shrink");
            }
          };
          // Collapse now if page is not at top
          collapseNavbar();
          // Collapse the navbar when page is scrolled
          document.addEventListener("scroll", collapseNavbar);
        }
    },
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.blurble-logo
{
    background-color: #41adf7;
    width: 32px;
    height: 32px;
    text-align: center;
    color: white;
    padding: 5px;
    border-radius: 4px;
    font-size: 14px;
    margin: 0px 8px;
}

.logo-text
{
    font-size: 20px;
    font-weight: 600;
}

.navbar
{
    background-color: transparent;
    transition: all 0.1s ease-in-out;

    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.navbar-shrink
{
    

    /*
    padding-bottom: 3px !important;
    padding-top: 3px !important;
    */
}
</style>
