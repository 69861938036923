<template>
    <!-- Login page -->
    <div class="container pt-4 pt-xl-5 mt-4">
        <div class="row pt-5">
            <div class="col-md-8 text-center text-md-start mx-auto">
                <div class="text-center">
                    <h1 class="display-4 fw-bold mb-5">Change the way you <span class="underline">write</span>.</h1>
                    <p class="fs-5 text-muted mb-5">
                        Say goodbye to bland descriptions and hello to creative and engaging write-ups that will capture the attention of your potential buyers.
                    </p>
                    <p class="fs-5 text-muted mb-5">
                        Sign up to be an early tester and get access to our beta version.
                    </p>
                    <form class="d-none d-sm-flex justify-content-center flex-wrap newsletter">
                        <div class="shadow-lg mb-3"><input class="form-control" type="email" name="email" placeholder="Your Email" /></div>
                        <div class="shadow-lg mb-3"><button class="btn btn-primary" type="submit">Subscribe </button></div>
                    </form>
                    <form class="d-sm-none d-flex justify-content-center flex-wrap">
                        <div class="shadow-lg m-1"><input class="form-control" type="email" name="email" placeholder="Your Email" /></div>
                        <div class="shadow-lg m-1"><button class="btn btn-primary" type="submit">Subscribe </button></div>
                    </form>
                    <hr class="m-4" />
                    <p class="fs-5 text-muted mb-2">
                    </p>
                    <router-link to="/login">Already Blurbling? Login here.</router-link>
                </div>
            </div>
            <div class="col-12 col-lg-10 mx-auto">
                <div class="text-center position-relative"><img class="img-fluid" src="assets/img/illustrations/meeting.svg" style="width: 800px;" /></div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */ 

export default {
    name: 'HomePage',
    components: {
    },
    data() {
        return {
        };
    },
    methods: {
    }
}



</script>

<style scoped>

.underline {
  position: relative;
}


.underline:after
{
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 8px;
    border-radius: 5px;
    background: #41adf7;
    z-index: -1;
}

.newsletter input
{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.newsletter button
{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
</style>
