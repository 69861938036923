import { defineStore } from "pinia";

export const usePropertyStore = defineStore({
    id: "property",
    state: () => ({
        property:{
            id: 0,
            address: "",
            land_area: 100,
            listingType: null,
            price: null,
            bedrooms: 1,
            bathrooms: 1,
            cars: 1,
            keywords: "",
            extra: "",
            features: [],
            writeup: "",
        }
    }),
    getters: {
        getProperty() {
            return this.property;
        },
    },
    actions: {
        setProperty(property) {
            this.property = property;
        },
    },
});