<template>
    <div>
        <section>
        <h3 class="fw-semibold"><i class="fa fa-house"></i> Property Write up</h3>
        <p class="fs-6">Tell us a little bit about the property. We'll recommend some key features you might not
            be aware about.</p>
    </section>
    <hr>
    <section>
        <form>
            <div class="row">
                <div class="col-md-6">
                    <div class="d-flex">
                        <div class="m-2 flex-grow-1"><label class="form-label fw-semibold">Address</label><input v-model="property.address"
                            class="form-control" type="text" placeholder="742 Evergreen Terrace...">
                        </div>
                        <div class="flex-grow-1 m-2"><label class="form-label fw-semibold">Listing
                                Type</label><select class="form-select" id="info_listing_type" v-model="property.listingType" >
                                <option value="1" selected="">Sale</option>
                                <option value="2">Rental</option>
                            </select>
                        </div>
                    </div>
                    <div class="d-flex">
                        
                        <div class="flex-grow-1 m-2"><label class="form-label fw-semibold">Price</label><input
                                id="info_price" v-model="property.price" class="form-control" type="text" placeholder="550000"></div>
                        <div class="flex-grow-1 m-2"><label class="form-label fw-semibold">Land Size (m<sup>2</sup>)</label><input
                                id="info_land_size" v-model="property.landSize" class="form-control" type="text" placeholder="300"></div>
                    </div>
                    <hr>
                    <div class="d-flex">
                        <div class="flex-grow-1 m-2"><label class="form-label fw-semibold">Bedrooms</label><input
                                class="form-control" v-model="property.bedrooms" type="number" step="1" min="0" max="10"></div>
                        <div class="flex-grow-1 m-2"><label class="form-label fw-semibold">Bathrooms</label><input
                                class="form-control" v-model="property.bathrooms" type="number" step="1" min="0" max="10"></div>
                        <div class="flex-grow-1 m-2"><label class="form-label fw-semibold">Car
                                Spaces</label><input class="form-control" v-model="property.cars" type="number" step="1" min="0" max="10"></div>
                    </div>
                    <div class="m-2"><label class="form-label fw-semibold">Keywords</label><input v-model="property.keywords"
                            class="form-control" type="text" placeholder="Air con, pool, garage, solar, ensuite...">
                    </div>
                </div>
                <div class="col">
                    <!-- foreach on the database -->
                    <FeatureList v-for="feature in featureDef.features"
                        :title="feature.title" :features="feature.features" v-bind:key="feature.title"></FeatureList>
                </div>
            </div>
            <div class="m-2"><label class="form-label fw-semibold">Extra Info</label><textarea v-model="property.extra"
                    class="form-control"></textarea></div>
        </form>
    </section>
    </div>
</template>

<script>
import FeatureList from './FeatureList.vue'
import featureDef from './featureDb.json'
import { usePropertyStore } from '@/stores/property';
import { storeToRefs } from 'pinia';

console.log(featureDef);

export default {
    setup() {
        const propertyStore = usePropertyStore();
        // create reactive refs to property object
        const { property } = storeToRefs(propertyStore);
        return {
            property
        }
    },
    name: 'PropertyOptions',
    props: {
    },
    components: {
        FeatureList
    },
    data() {
        return {
            featureDef: featureDef
        }
    },
}
</script>