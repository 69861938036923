<template>
    <!-- Loading spinner in the dead middle of the screen -->
    <div class="d-flex justify-content-center align-items-center" style="height: 50vh;">
        <div>
            <div class="d-block text-center">
                <div class="spinner-grow" role="status">
                </div>
            </div>
            <div class="d-block">
                <strong>Generating your writeup...</strong>
                <br />
                <small>This may take a few seconds.</small>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoadingSpinner',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
