import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import * as VueRouter from 'vue-router'
import axios from 'axios';

// 1. Define route components.
// These can be imported from other files
import Home from './components/HomePage.vue';
import AppPage from './components/NewPropertyPage.vue';
import Login from './components/LoginPage.vue';
import Profile from './components/ProfilePage.vue';

// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile
    },
    {
        path: '/app',
        name: 'app',
        component: AppPage
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = VueRouter.createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: VueRouter.createWebHashHistory(),
    routes, // short for `routes: routes`
})


const app = createApp(App);

// get the base url from the current url and use it for the api
const url = new URL(window.location.href);
var baseURL = url.origin + '/api/v1/';

// check if vue is in development mode
var isDev = process.env.NODE_ENV === 'development';

if (isDev) {
    baseURL = 'http://localhost:3000/api/v1/';
}

//isDev=false;

app.config.globalProperties.$isDev = isDev;

var api = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
    }
});

// inject our token into the header of every request
api.interceptors.request.use(function (config) {

    const token = localStorage.getItem('token');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
});

// check response for permission denied and remove token if found
api.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status === 401) {
        localStorage.removeItem('token');
        router.push({ name: 'login' });
    }
    return Promise.reject(error);
});

app.config.globalProperties.$api = api;

class Auth
{
    constructor() {
        this.is_auth = this.is_authenticated();
        this.user = this.get_user();

        console.log(JSON.stringify(this.user));
    }

    parseJwt (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
    }

    get_token_data()
    {
        if (localStorage.getItem('token') === null) {
            return null;
        }

        return this.parseJwt(localStorage.getItem('token'));
    }

    get_user()
    {
        return JSON.parse(localStorage.getItem('user'));
    }

    is_authenticated() {

        var tdata = this.get_token_data();

        if (tdata !== null) {
            var now = new Date();
            var exp = new Date(tdata.exp * 1000);
            if (now > exp) {
                localStorage.removeItem('token');
                return false;
            }
        }
        else
        {
            return false;
        }

        return localStorage.getItem('token') !== null;
    }

    go_to_login() {
        router.push({ name: 'login' });
    }

    logout() {
        localStorage.removeItem('token');
        this.is_auth = false;
        this.go_to_login();
    }

    // create a promise to login
    async login(username, password) {
        return new Promise((resolve, reject) => {
            api.post('auth/login', { username: username, password: password })
            .then(response => {
                var token = response.data.result.token;
                localStorage.setItem('token', token);
                localStorage.setItem('user', JSON.stringify(response.data.result.user));
                this.user = response.data.result.user;

                console.log(response.data);

                this.is_auth = true;
                
                resolve({ success: true, message: 'Login successful' });
            })
            .catch(error => {
                console.log(error);
                this.is_auth = false;
                reject({ success: false, message: error.response.data.message });
            });
        });
    }
}

app.config.globalProperties.$auth = new Auth();

app.use(createPinia());
app.use(router);
app.mount('#app');
