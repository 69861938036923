<template>
    <!-- Page that shows user profile info -->
    <div class="container pt-4 pt-xl-5 mt-5">
        <div class="container">
            <div class="p-4 text-center">
                <div>
                    <img class="img-fluid rounded-circle mb-3" :src="this.$auth.user.settings.profile.dp" width="140" />
                    <h3>{{ this.$auth.user.settings.profile.first_name }}</h3>
                </div>
            </div>
            <div class="p-4 card shadow">
                <form class="form">
                    <div class="mb-3">
                        <label for="first_name" class="form-label">First Name</label>
                        <input type="text" class="form-control" id="first_name" v-model="this.$auth.user.settings.profile.first_name" />
                    </div>
                    <div class="mb-3">
                        <label for="last_name" class="form-label">Last Name</label>
                        <input type="text" class="form-control" id="last_name" v-model="this.$auth.user.settings.profile.last_name" />
                    </div>
                    <div class="mb-3">
                        <label for="email" class="form-label">Email</label>
                        <input type="email" class="form-control" id="email" v-model="this.$auth.user.settings.profile.email" />
                    </div>
                    <div class="mb-3">
                        <label for="dp" class="form-label">Display Picture</label>
                        <input type="text" class="form-control" id="dp" v-model="this.$auth.user.settings.profile.dp" />
                    </div>
                </form>
            </div>
        </div>

        <div class="text-muted">{{ this.$auth.user }}</div>
    </div>
</template>

<script>
/* eslint-disable */ 

export default {
    name: 'ProfilePage',
    components: {
    },
    data() {
        return {
        };
    },
    methods: {
    }
}



</script>

<style scoped>

</style>
