<template>
    <div class="m-2 mb-4"><label class="form-label fw-semibold">{{ title }}</label>
        <div class="row my-3" v-for="row in feature_rows" v-bind:key="row.value">
            <div class="col-md-6" v-for="feature in row" v-bind:key="feature">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox"
                        v-bind:id="'f_' + nameToId(this.title) + '-' + nameToId(feature)" v-bind:key="feature" v-bind:value="property.features" v-on:change="checkChange($event, feature)">
                    
                    <label class="form-check-label" v-bind:for="'f_' + nameToId(this.title) + '-' + nameToId(feature)" >{{ feature }}</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

function nameToId(name) {
    return name.toLowerCase().replace(/ /g, '_');
}

function checkChange(caller, value)
{
    //var group = nameToId(this.title);
    var feature = nameToId(this.title) + "-" + nameToId(value);

    var index = this.property.features.indexOf(feature);

    if (caller.target.checked && index == -1) {
        this.property.features.push(feature);
    }
    if (index > -1 && !caller.target.checked) {
        this.property.features.splice(index, 1);
    }
}

function segmentFeaturesToRows(features) {
    var rows = [];
    var row = [];
    for (var i = 0; i < features.length; i++) {
        row.push(features[i]);
        if (i % 2 == 1) {
            rows.push(row);
            row = [];
        }
    }
    if (row.length > 0) {
        rows.push(row);
    }
    return rows;
}

import { usePropertyStore } from '@/stores/property';
import { storeToRefs } from 'pinia';

export default {
    setup() {
        const propertyStore = usePropertyStore();
        // create reactive refs to property object
        const { property } = storeToRefs(propertyStore);

        return {
            property
        }
    },
    name: 'FeatureList',
    props: {
        title: String,
        features: Array
    },
    emits: ['onFeatureChange'],
    data() {
        return {
            feature_rows: segmentFeaturesToRows(this.features),
        }
    },
    methods: {
        nameToId,
        checkChange,
    },
    watch: {
    },
    mounted() {
        /* eslint-disable */
        for(var i = 0; i < this.property.features.length; i++) {
            var feature = this.property.features[i];
            var id = nameToId(feature);
            var element = document.getElementById("f_" + id);
            console.log(id);
            if (element) {
                element.checked = true;
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
